import type { ChangeEvent } from 'react';
import React from 'react';
import { classes } from './FileInput.st.css';

interface FileInputProps {
  accept?: string;
  className?: string;
  onChange: (files: File[]) => void;
  disabled?: boolean;
  title?: string;
  multiple?: boolean;
}

export const FileInput: React.FC<FileInputProps> = (props) => {
  const id = `file_input_${Math.floor(Math.random() * 9999)}` as const;

  const a11yProps = {
    role: 'button',
    'aria-label': props.title,
  };

  const accept =
    props.accept ||
    '.jpg,.png,.gif,.jpeg,.jpe,.jfif,.bmp,.heic,.heif,.tfif,.tif,.webp';

  const handleChange = (
    e: ChangeEvent<{ files: FileList | null; value: any }>,
  ) => {
    props.onChange(Array.from(e.target.files || []));
    e.target.value = null;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label
      htmlFor={id}
      className={props.className}
      title={props.title}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <input
        {...a11yProps}
        className={classes.visuallyHidden}
        disabled={props.disabled}
        id={id}
        type="file"
        onChange={handleChange}
        accept={accept}
        multiple={props.multiple}
      />
      {props.children}
    </label>
  );
};
