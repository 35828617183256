import React, { useEffect } from 'react';
import { Input, IconButton } from 'wix-ui-tpa';
import {
  Search as SearchIcon,
  Close as CloseIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { st, classes } from './Search.st.css';

export interface SearchProps {
  value?: string;
  fullWidth?: boolean;
  forceOpen?: boolean;
  bw?: boolean;
  withCloseButton?: boolean;
  withBorder?: boolean;
  placeholder?: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onClose?(): void;
}

export const Search: React.FC<SearchProps> = (props) => {
  const { placeholder, withCloseButton, forceOpen, fullWidth, withBorder, bw } =
    props;

  const [isExpanded, setIsExpanded] = React.useState(forceOpen);
  const [value, setValue] = React.useState(props.value || '');

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  return (
    <div
      className={st(
        classes.root,
        { isExpanded, fullWidth, withBorder, bw },
        props.className,
      )}
    >
      <Input
        className={classes.input}
        placeholder={placeholder}
        autoFocus={forceOpen ? false : isExpanded}
        onBlur={forceOpen ? () => {} : handleBlur}
        onFocus={expand}
        onChange={handleChange}
        value={value}
        prefix={
          <IconButton
            aria-label="search"
            icon={<SearchIcon />}
            onClick={expand}
          />
        }
        suffix={
          isExpanded &&
          withCloseButton &&
          value && (
            <IconButton
              aria-label="clear"
              icon={<CloseIcon />}
              onClick={handleCloseClick}
            />
          )
        }
      />
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    props.onChange?.(event);
  }

  function expand() {
    setIsExpanded(true);
  }

  function collapse() {
    setIsExpanded(false);
  }

  function handleBlur() {
    if (!props.value) {
      collapse();
    }
  }

  function handleCloseClick() {
    collapse();
    setValue('');
    props.onClose?.();
  }
};
