import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Text, TextTypography } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { getText } from 'ricos-content/libs/content-application';

import { Button } from 'wui/Button';
import { Box } from 'common/components/Box/Box';

import { selectCurrentUser, selectDraft } from 'store/selectors';

import { PluginsToolbar, IPluginsToolbarProps } from './PluginsToolbar';
import { NEW_POST_BOX } from './dataHooks';

import settingsParams from '../../../../settingsParams';

import { classes, st } from './CreatePost.st.css';
import {
  ensureDraftContent,
  fromDraft,
} from 'ricos-content/dist/lib/converters';

export interface ICreatePostProps {
  className?: string;
  openPlugin?: IPluginsToolbarProps['onClick'];

  onClick(): void;
}

export function CreatePost(props: ICreatePostProps) {
  const { onClick, openPlugin } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const user = useSelector(selectCurrentUser);
  const draft = useSelector(selectDraft);

  const draftText = useMemo(
    () =>
      draft
        ? getText(fromDraft(ensureDraftContent(draft)))
            .join(' ')
            .trim()
        : undefined,
    [draft],
  );

  const placeholder =
    settings.get(settingsParams.newPostBoxLabel) ||
    t('groups-web.discussion.create-post.placeholder');

  return (
    <Box
      tabIndex={0}
      role="button"
      data-hook={NEW_POST_BOX}
      withSideBorders={!isMobile}
      onClick={onClick}
      aria-label={draftText || placeholder}
      className={st(
        classes.root,
        {
          mobile: isMobile,
          hasDraft: !!draftText,
        },
        props.className,
      )}
    >
      <div className={classes.createPostRow}>
        <Avatar src={user.imageUrl} className={classes.avatar} />
        <Text
          typography={TextTypography.runningText}
          className={classes.placeholder}
        >
          {draftText || placeholder}
        </Text>
        <Button fullWidth={isMobile}>
          {t('groups-web.discussion.new-post.cta')}
        </Button>
      </div>

      {!isMobile && (
        <PluginsToolbar
          onClick={openPlugin}
          className={classes.pluginsToolbar}
        />
      )}
    </Box>
  );
}

CreatePost.displayName = 'CreatePost';
