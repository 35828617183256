import React from 'react';
import { Components } from 'react-virtuoso';
import { TransitionGroup } from 'react-transition-group';

import type { IFeedItem } from 'api/feed/types';

import { IVirtuosoContext } from '../types';
import { classes } from '../FeedItemList.st.css';

export const List: Components<IFeedItem, IVirtuosoContext>['List'] =
  React.forwardRef((props, ref) => {
    return (
      <div {...props} className={classes.list} ref={ref}>
        <TransitionGroup exit={false} component={null}>
          {props.children}
        </TransitionGroup>
      </div>
    );
  });
